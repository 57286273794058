
<template>
    <div class="f-cl-c">
        <el-page-header @back="$router.go(-1)" :content="'发布帖子'"></el-page-header>
        <TextArea class="fs-18" :placeholderValue="'写个标题吧~'" :modelValue="postHead" @getTextAreaValue="checkInputLength"
            :maxlengthValue="inputMaxLength" :TextAreaisLimit="true"></TextArea>
        <div id='quillEditorQiniu'>
            <!-- 基于elementUi的上传组件 el-upload begin-->
            <el-upload class="avatar-uploader" action="#" :accept="'image/*,video/*'" :show-file-list="false"
                :http-request="uploadForumOssFile">
            </el-upload>
            <!-- 基于elementUi的上传组件 el-upload end-->
            <quill-editor class="editor" v-model="content" ref="customQuillEditor" :options="editorOption">
            </quill-editor>
            <span class="wordNumber">{{ editPostContentLength }}/{{ maxPostContentLength }}</span>
        </div>
        <el-button @click="uploadPOST" type="primary" round class="w--30">发布帖子</el-button>
    </div>
</template>

<script>

//实现quill-editor编辑器拖拽上传图片
import * as Quill from 'quill'
import { ImageDrop } from 'quill-image-drop-module'
Quill.register('modules/imageDrop', ImageDrop)

//实现quill-editor编辑器调整图片尺寸
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageResize', ImageResize)

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['image', 'video'],
];
import { UrlDecodeType } from '@/mixins/UrlDecodeMixin';
export default {
    mixins: [UrlDecodeType],
    data() {
        return {
            uploadUrlPath: "没有文件上传",
            quillUpdateImg: false,
            content: '',//帖子内容 即富文本内容
            editorOption: {
                placeholder: '请输入',
                modules: {
                    imageResize: {
                        displayStyles: {
                            backgroundColor: 'black',
                            border: 'none',
                            color: 'white'
                        },
                        modules: ['Resize', 'DisplaySize', 'Toolbar']
                    },
                    toolbar: {
                        container: toolbarOptions,  // 工具栏
                        handlers: {
                            'image': function (value) {
                                if (value) {
                                    document.querySelector('#quillEditorQiniu .avatar-uploader input').click()
                                } else {
                                    this.quill.format('image', false);
                                }
                            },
                            'video': function (value) {
                                if (value) {
                                    document.querySelector('#quillEditorQiniu .avatar-uploader input').click()
                                } else {
                                    this.quill.format('video', false);
                                }
                            },
                        }
                    }
                }
            },
            postDetail: '',
            postHead: '',//帖子标题
            inputMaxLength: 30,//标题限制最长字数
            mobjectArray: [null, null, null, null, null, null, null, null, null],//媒体对象数组,固定长度为9,最多接收9个媒体对象
            imageNumber: 0,
            editPostContentLength: 0,//帖子内容的长度
            maxPostContentLength: 3000,
            IpAddressInfo: {},
            imgUrl: 'https://rmsearch-public-t.oss-cn-guangzhou.aliyuncs.com/forum_temp/2023-09/0ed6142c5d864b0fa34b39bde6bd360c.jpg',
            imgUrlList: []
        }
    },
    watch: {
        'content': {
            handler(newVal) {
                let watchContent = this.getSimpleText(newVal)
                this.editPostContentLength = watchContent.length
                if (watchContent.length >= this.maxPostContentLength) {
                    this.content = watchContent.slice(0, 3000)
                    return this.$common.message(`字数不能超过${this.maxPostContentLength}!`, 'warning', 1500)
                }
            },
            // 深度观察监听
            deep: true,
            immediate: true
        }
    },
    created() {
        this.$CommonRequests.getIpAddress().then((res) => {
            if (res.code == 200) {
                this.IpAddressInfo = res.data
            }
        })
    },
    methods: {
        // 检查标题输入的字数
        checkInputLength(data) {
            this.postHead = data.value
            this.$common.inputMaxLength(data.value, this.inputMaxLength)
        },
        // 帖子图片视频文件上传接口
        uploadForumOssFile(params) {
            console.log(params)
            let that = this;
            const formdata = new FormData();
            formdata.append("file", params.file)
            formdata.append("userId", parseInt(that.$store.state.userId))
            formdata.append("token", that.$getStorage('token'))
            that.$http.uploadForumOssFile(formdata).then(res => {
                // 临时地址
                let imgUrl = res.data;
                that.imgUrlList.push(imgUrl)
                // 类型
                let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
                // 获取富文本组件实例
                let quill = that.$refs.customQuillEditor.quill;
                // 获取光标所在位置
                let length = quill.getSelection().index;
                console.log(imgUrl)
                // 插入图片||视频  res.info为服务器返回的图片地址
                if (that.mobjectArray.indexOf(null) != -1) {
                    if (type == 'mp4' || type == 'MP4') {
                        quill.insertEmbed(length, 'video', imgUrl)
                    } else {
                        quill.insertEmbed(length, 'image', imgUrl)
                    }
                    that.mobjectArray[that.imageNumber] = imgUrl
                    that.imageNumber++
                } else {
                    that.$common.message('图片或视频一共只能上传9个！', 'warning', 2000)
                }
                // 调整光标到最后
                quill.setSelection(length + 1)
                //取消上传动画
                this.quillUpdateImg = false;
                // that.content = that.content.replace(/src/g,":src")
                // console.log(that.content)
            })
        },
        // 获取富文本中的纯文本
        getSimpleText(html) {
            let re1 = new RegExp('<.+?>', 'g')
            let msg = html.replace(re1, '')
            msg = msg.replace('&nbsp;', '')
            return msg
        },
        // ------------------------------------------------------------
        // 上传帖子
        uploadPOST() {
            let that = this, { postHead, content } = that;
            if (!postHead) {
                return that.$common.message('请输入帖子标题！', 'warning', 1500)
            }
            if (!content) {
                return that.$common.message('请输入帖子内容！', 'warning', 1500)
            }
            console.log('111',content)
            // switch (this.queryType) {
            //     case 'ProductExchange':
            //         this.addForumArticle()
            //         break;
            //     case 'ShiLiaoZaTan':
            //         this.addForumChatArticle()
            //         break;
            // }
        },
        // 获取参数
        getParams() {
            let that = this, { postHead, content, mobjectArray } = that,
                interceptContent = that.getSimpleText(content).slice(0, 100),
                { ip, province, city, districts } = that.IpAddressInfo
                // NEWcontent = '';


                // let item = 'http';
                // let reg = eval("/" + item + "/g");
                // content = content.replace(reg, `mObJ_10086_`+[1])
                // console.log(content)

                // https://rmsearch-public-t.oss-cn-guangzhou.aliyuncs.com/forum_temp/2023-09/9db70df217414ff685002b27312e2b5e.jpg
                // console.log(content)


            that.imgUrlList.forEach((item, index) => {
                // let reg = eval("/" + item.toString + "/g"),
                // name = `mObJ_10086_`+[index]
                // console.log(reg)
                // console.log(name)
                // console.log(eval(reg))
                content = content.replaceAll(item, `mObJ_10086_`+[index])
                // console.log(item)
                console.log(content)

            })
            // console.log(content)
            // console.log(content.split('src="'))
            // let contentList = content.split('src=');
            // contentList[1] = 'imgUrl'
            // console.log(contentList)
            // let NEWcontent = contentList.join("");
            // console.log(NEWcontent)
            return {
                postUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                postContent: content,//帖子内容 即富文本内容
                postHead,//帖子标题
                mobjectArray,//图片或媒体文件数组
                previewContent: interceptContent,//内容预览 即内容截取前100个字
                postLocation: province + '*' + city + '*' + districts,//发帖来源地 即广东，韶关。。。
                sourceIp: ip,//发帖来源IP 
                sourceType: 'PC'
            }
        },
        // 产品交流上传帖子
        addForumArticle() {
            let that = this;
            that.$http.addForumArticle(that.getParams()).then(res => {
                if (res.code == 200) {
                    that.$common.message(res.msg, 'success')
                    that.$common.DelayedBack()
                }
            })
        },
        // 识料杂谈上传帖子
        addForumChatArticle() {
            // this.getParams()
            let that = this;
            that.$http.addForumChatArticle(that.getParams()).then(res => {
                if (res.code == 200) {
                    that.$common.message(res.msg, 'success')
                    that.$common.DelayedBack()
                }
            })
        },
    },
}
</script>

<style>
.editor {
    line-height: normal !important;
    width: 1100px;
    height: 550px;
    margin-bottom: 80px;
}

.wordNumber {
    display: block;
    width: 98%;
    text-align: right;
}
</style>
